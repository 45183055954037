<app-navbar-two></app-navbar-two>

<div class="contact-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Contact Us</h2>
            </div>
        </div>
    </div>
</div>

<div class="contact-area">
    <div class="container">
        <div class="row contact-box">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-maps-and-flags"></i>
                    <h3>Location</h3>
                    <ul>
                        <li>"Saileela", Plot No. 4, Third Floor, </li>
                        <li>Narayan Hut Society, Bhosari, Pune 411 039</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-call"></i>
                    <h3>Phone</h3>
                    <ul>
                        <li>+91 8087 023 818</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-envelope"></i>
                    <h3>Email</h3>
                    <ul>
                        <li>info&#64;lexvin.in</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>Get in Touch with Lexvin</h3>
                        <p>We're here to assist you with all your real estate legal needs. Whether you're buying, selling, or investing in property, or facing a property dispute, our team is ready to provide expert guidance and support. Contact us today for a free consultation.</p>
                    </div>
                   
                    <form>
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="subject" id="subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>