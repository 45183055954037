<app-navbar-two></app-navbar-two>

<div class="pricing-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Pricing</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Pricing</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>Starter</h2>
                        <h3><sup>$</sup>30<span>/Month</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Up to 3 chat operators</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>Starter</h2>
                        <h3><sup>$</sup>30<span>/Month</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Up to 3 chat operators</li>                           
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>Standard</h2>
                        <h3><sup>$</sup>49<span>/Month</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Up to 5 chat operators</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>Premium</h2>
                        <h3><sup>$</sup>69<span>/Month</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Up to 10 chat operators</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>