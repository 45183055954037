<app-navbar-two></app-navbar-two>

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Legal Services</h2>
            </div>
        </div>
    </div>
</div>


<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/1.jpg" alt="about image">
                </div>
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <h2>Litigation</h2>
                        <h5>Don't Let Real Estate Disputes Derail Your Plans</h5>
                    <p> Real estate litigation can be a complex and time-consuming process. Whether you're
                        facing a property dispute, a landlord-tenant issue, or a breach of contract, Lexvin is
                        here to protect your rights and interests.</p>
                        <ul>
                            <li><span>Our Approach:</span> We understand the emotional and financial toll that litigation
                                can take. That's why we prioritise personalised attention, strategic planning, and
                                a commitment to resolving your case as efficiently as possible.</li>
                                <li><span>Expertise:</span>  Our team has extensive experience handling a wide range of
                                    real estate disputes. We'll guide you through the legal process, advocate for
                                    your rights, and work tirelessly to achieve a favourable outcome.</li>
                        </ul>
                    </div>

                    
                        
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/2.png" alt="about image">
                </div>
            </div>                    
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <h2>Title Verification</h2>
                        <h5>Secure Your Investment with Thorough Title Verification</h5>
                        <p>Buying a property is a major decision. Don't leave your investment at risk. Our
                        comprehensive title verification process ensures that the property you're purchasing
                        has a clear and marketable title, free from any hidden issues that could affect your
                        ownership rights.</p>
                        <ul>
                            <li><span>Peace of Mind:</span> Our meticulous investigation of property records, title deeds,
                                and potential encumbrances provides you with the peace of mind that comes
                                with knowing your investment is secure.</li>
                                <li><span>Protection from Fraud:</span>  We'll uncover any fraudulent activities or
                                    misrepresentations related to the property's ownership, safeguarding you from
                                    costly legal battles down the line.</li>
                        </ul>
                    </div>

                    
                        
                </div>
            </div>            
        </div>
    </div>
</section>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/3.png" alt="about image">
                </div>
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <h2>Drafting & Review</h2>
                        <h5>Craft Clear and Effective Legal Documents</h5>
                    <p>Legal documents are the foundation of any real estate transaction. Whether it's a
                        purchase agreement, lease agreement, or other legal document, precise language and
                        clear terms are essential to avoid misunderstandings and protect your interests.</p>
                        <ul>
                            <li><span>Drafting:</span> Our experienced lawyers will draft comprehensive and legally sound
                                documents tailored to your specific needs.</li>
                                <li><span>Review:</span>  If you already have legal documents, we'll meticulously review them to
                                    identify potential risks and ensure they align with your best interests.</li>
                        </ul>
                    </div>                        
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/4.png" alt="about image">
                </div>
            </div>                    
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <h2>Registration</h2>
                        <h5>Hassle-Free Property Registration Services</h5>
                    <p>Registering your property documents can be a complex and time-consuming process.
                        At Lexvin, we simplify the registration process, ensuring all documents are filed
                        correctly and efficiently.</p>
                        <ul>
                            <li><span>Transparent and Efficient:</span> We'll handle all the paperwork and formalities,
                                keeping you informed every step of the way.</li>
                                <li><span>Cost-Effective:</span>  Our competitive pricing ensures that you receive high-quality
                                    registration services without breaking the bank.</li>
                        </ul>
                    </div>
                </div>
            </div>           
        </div>
    </div>
</section>