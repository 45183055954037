import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private showPopup1Source = new BehaviorSubject<boolean>(false);
  showPopup1$ = this.showPopup1Source.asObservable();

  // private showPopup2Source = new BehaviorSubject<boolean>(false);
  // showPopup2$ = this.showPopup2Source.asObservable();

  showFirstPopup() {
    this.showPopup1Source.next(true);
  }

  hideFirstPopup() {
    this.showPopup1Source.next(false);
    // Simulate a delay before showing the second popup
    //setTimeout(() => this.showPopup2Source.next(true), 3000); // Adjust delay in milliseconds (here 3 seconds)
  }

  // hideSecondPopup() {
  //   this.showPopup2Source.next(false);
  // }
}