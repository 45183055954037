import { Component, OnInit } from '@angular/core';
import { PopupService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  showPopup1: boolean = false;
  //showPopup2: boolean = false;

  constructor(private popupService: PopupService) {}

  ngOnInit() {
    this.popupService.showPopup1$.subscribe(show => this.showPopup1 = show);
    //this.popupService.showPopup2$.subscribe(show => this.showPopup2 = show);
  }

  onClose1() {
    this.popupService.hideFirstPopup();
  }

  // onClose2() {
  //   this.popupService.hideSecondPopup();
  // }
}
