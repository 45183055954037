<div *ngIf="showPopup1" class="popup popup-1">
    <h2 style="color: white;">Disclaimer:</h2>
    <p>In accordance with the Bar Council of India's Rules on professional
        standards, this website is not intended to solicit clients or advertise our services. The
        information provided is for informational purposes only and should not be considered
        legal advice. We invite you to contact us for a formal consultation. By clicking on 'I
        AGREE' below, the website visitor acknowledges that the information provided is meant
        only for his/her understanding of the Firm and its services.</p>
    <button style="color: white; border: 1px solid white;" (click)="onClose1()">Close</button>
  </div>
  
  <!-- <div *ngIf="showPopup2" class="popup popup-2">
    <h2 style="color: white;">Download our Legal Guide (Popup 2)!</h2>
    <p>Get valuable insights on Indian Law. Download our free guide now.</p>
    <button  style="color: white; border: 1px solid white;" (click)="onClose2()">Close</button>
  </div> -->
  