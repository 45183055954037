<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-9">
                <div class="header-left-text">
                    <ul>
                        <li><a href="tel:+918087023818"><i class="flaticon-call"></i> +918087023818</a></li>
                        <li><a href="mailto:info&#64;lexvin.com"><i class="flaticon-envelope"></i> info&#64;lexvin.com</a></li>
                        <li><i class="flaticon-maps-and-flags"></i>"Saileela," Plot No. 4, Third Floor, Narayan Hut Society, Bhosari, Pune 411 039</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-3">
                <div class="header-right text-end">
                    <div class="header-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img class="logo_bg" src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img class="logo_bg" src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item">
                            <a routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/founder" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Founder</a>

                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/privacy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy policy</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog One</a></li>

                                <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Two</a></li>

                                <li class="nav-item"><a routerLink="/blog-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Three</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul> -->
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="other-option">
                        <div class="search-bar">
                            <i class="flaticon-search search-icon"></i>
                            <div class="search-form">
                                <form>
                                    <input type="text" placeholder="Search" class="search-input">
                                    <button type="submit">
                                        <i class="flaticon-search search-btn"></i>
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div class="sidebar-toggle">
                            <button type="button" class="btn btn-demo toggle-button navbar-toggle" data-bs-toggle="modal" data-bs-target="#sidebar-right">
                                <i class="flaticon-list"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>