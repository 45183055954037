<app-navbar-two></app-navbar-two>

<div class="about-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>About Us</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/1.jpg" alt="about image">
                </div>
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>The Modern Way to Make Succesful Business</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>

                    <p>Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="theory-section theory-style-two">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Meet Our Founder</h2>
                    <p>Advocate Vinayak Aher, a seasoned real estate lawyer in Pune with over a decade of experience, founded Lexvin with a commitment to providing exceptional legal services. His expertise extends throughout Maharashtra, ensuring you receive the best possible guidance and representation.</p>
                    
                    <div class="signature-box">
                        <p>Vinayak K. Aher</p>
                        <span>Founder and CEO</span>
                    </div>
                    
                </div>
                <div class="theme-button">
                    <p> <a routerLink="/founder" class="default-btn">Read More</a></p>
                </div> 
            </div>

            <div class="theory-img">
                <img src="assets/img/theory-img.jpg" alt="theory image">
            </div>
        </div>
    </div>
</div>

<div class="video-style-two video-style-three">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-section service-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 service-img"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Servicesfor Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i>Litigation</li>
                        <li><i class="flaticon-checkmark"></i>Title Verification</li>
                        <li><i class="flaticon-checkmark"></i>Drafting & Review</li>
                        <li><i class="flaticon-checkmark"></i>Registration</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>Schedule Your Free Consultation Today</h3>
                        <p>Our experienced team is ready to discuss your specific needs and provide tailored solutions to help you achieve your real estate goals.</p>
                    </div>
                   
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>