<app-navbar-two></app-navbar-two>

<div class="portfolio-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Founder</h2>
            </div>
        </div>
    </div>
</div>

<section class="about-section about-style-two pb-100"> 
    <div class="container-fluid">
        <div class="row">
            <div class="about-image"></div>

            <div class="col-lg-7 offset-lg-5 p-0">
                <div class="about-text">
                    <div class="section-title">
                        <span>Meet Our Founder</span>
                        <h2>Vinayak K. Aher</h2>
                   
                   <p>Advocate Vinayak Aher is the founder and driving force behind Lexvin, a firm dedicated
                    to providing exceptional legal services in the realm of real estate. His impressive
                    academic journey began with a Bachelor's degree in Science from Fergusson College
                    in Pune, followed by a Bachelor of Laws (LLB) from the esteemed ILS Law College,
                    Pune. He further honed his legal acumen with a Master of Laws (LLM) from the
                    University of East Anglia in the United Kingdom.</p>
                   <p>This diverse educational background, encompassing both scientific and legal disciplines, has equipped Advocate Aher with a unique ability to analyse complex real estate matters from multiple angles. He has over a decade of experience practising real estate law in Maharashtra, with a strong focus on Pune, but his expertise extends beyond regional boundaries</p>
                   <p>Advocate Aher is enrolled with the Bar Council of Maharashtra and Goa (MAH/818/2011), and his reputation as a trusted advisor precedes him. Clients appreciate his meticulous approach, his ability to simplify complex legal concepts, and his unwavering commitment to achieving favourable outcomes. Whether you are buying your first home, investing in commercial property, or facing a real estate dispute, Advocate Aher is dedicated to protecting your interests and guiding you through every step of the legal process.</p>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>