<app-navbar-two></app-navbar-two>

<div class="main-banner banner-style-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Your Trusted Partner for Real Estate Law</h1>                    
                    <p>Navigating real estate transactions in Maharashtra can be complex and daunting. Whether you're buying your dream home in Pune, investing in commercial property in Mumbai, or facing a property dispute in Nagpur, Lexvin is here to simplify the process and protect your interests. We offer comprehensive legal services tailored to the unique needs of individuals and businesses across Maharashtra.</p>
                </div>

                <div class="banner-image">
                    <img src="assets/img/banner/2.png" alt="banner image">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-section pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span></span>
            <h2>Our Comprehensive Legal Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-one">
                   <a href="/legalservices"> <i><img class="icon-img" src="assets/img/icons/1.png"></i></a>
                    <h3>Litigation</h3>
                    <p>We provide tenacious representation in property disputes throughout Maharashtra, leveraging our litigation expertise to protect your rights and achieve favourable outcomes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-two">
                    <a href="/legalservices"><i><img class="icon-img"  src="assets/img/icons/2.png"></i></a>
                    <h3>Title Verification</h3>
                    <p>Our meticulous title verification process ensures your property purchase is secure and free from hidden issues, giving you peace of mind in your investment.</p>
                </div>
            </div>

            

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-four">
                    <a href="/legalservices"><i><img class="icon-img"  src="assets/img/icons/3.png"></i></a>
                    <h3>Drafting & Review</h3>
                    <p>We meticulously draft and review all real estate documents, ensuring clarity, accuracy, and compliance with Maharashtra's specific legal requirements.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-five">
                    <a href="/legalservices"><i><img class="icon-img"  src="assets/img/icons/4.png"></i></a>
                    <h3>Registration</h3>
                    <p>We simplify and expedite the property registration process in Maharashtra, making it hassle-free and efficient for you.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section about-style-two pb-100"> 
    <div class="container-fluid">
        <div class="row">
            <div class="about-image"></div>

            <div class="col-lg-7 offset-lg-5 p-0">
                <div class="about-text">
                    <div class="section-title">
                        <span>Meet Our Founder</span>
                        <h2>Vinayak K. Aher</h2>
                    </div>
                    <p>Advocate Vinayak Aher, a seasoned real estate lawyer in Pune with over a decade of experience, founded Lexvin with a commitment to providing exceptional legal services. His expertise extends throughout Maharashtra, ensuring you receive the best possible guidance and representation.</p>
                    <div class="theme-button">
                        <a routerLink="/founder" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <div class="video-style-two">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="service-section service-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 service-img"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Servicesfor Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i>Building Networking</li>
                        <li><i class="flaticon-checkmark"></i>Software Development</li>
                        <li><i class="flaticon-checkmark"></i>Business Strategy</li>
                        <li><i class="flaticon-checkmark"></i>Business Management</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

 <section class="blog-section pt-100 pb-70">
  <!--  <div class="container">
        <div class="section-title text-center">
            <span>Our Blog</span>
            <h2>Our Latest Blogs</h2>    
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/1.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">The next genaration IT will change the world</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/"><p>Aikin Ward</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/2.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Content writing demand day by day increasing</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/"><p>John Smith</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/3.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Don't buy a tech gift until you read these rules image</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/"><p>Sarah Tylor</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section> 

<div class="contact-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>Schedule Your Free Consultation Today</h3>
                        <p>Our experienced team is ready to discuss your specific needs and provide tailored
                            solutions to help you achieve your real estate goals.</p>
                    </div>
                   
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>