<div class="newsletter-section">
    <div class="container">
        <div class="newsletter-area">
            <h2>Subscribe Our Newsletter</h2>

            <div class="col-lg-6 offset-lg-3 p-0">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                    <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                </form>
            </div>
            <img src="assets/img/shapes/2.png" class="subscribe-shape" alt="shape">
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="logo"></a>
                    </div>
                    <p>Lexvin: Your Trusted Partner for Real Estate Law</p>
                    <div class="footer-social">
                        <a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/legalservices"><i class="flaticon-right"></i> Litigation</a></li>
                        <li><a routerLink="/legalservices"><i class="flaticon-right"></i> Title Verification</a></li>
                        <li><a routerLink="/legalservices"><i class="flaticon-right"></i> Drafting & Review</a></li>
                        <li><a routerLink="/legalservices"><i class="flaticon-right"></i> Registration</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a routerLink="/"><i class="flaticon-right"></i> Home</a></li>
                        <li><a routerLink="/team"><i class="flaticon-right"></i> Team</a></li>
                        <!-- <li><a routerLink="/blog"><i class="flaticon-right"></i> Blog</a></li> -->
                        <li><a routerLink="/founder"><i class="flaticon-right"></i> Founder</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Find Us</h3>

                    <ul>
                        <li><a href="https://maps.app.goo.gl/vuWDAkHfMA43s6BBA" target="_blank"><i class="flaticon-maps-and-flags"></i>Pune 411 039</a></li>
                        <li><a href="tel:+91 8087023818"><i class="flaticon-call"></i> +91 8087 023 818</a></li>
                        <li><a href="mailto:info&#64;lexvin.in"><i class="flaticon-envelope"></i> info&#64;lexvin.in</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 lh-1 text-start">
                    <p>Copyright © [2024] Lexvin. All rights reserved.</p>
                </div>

                <div class="col-md-6 lh-1 text-end">
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <div class="right-modal">
    <div class="modal fade right" id="sidebar-right" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 p-0">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/"><img src="assets/img/modal/2.jpg" alt="home"></a>
                                           <h3><a routerLink="/">Home</a></h3>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/team"><img src="assets/img/modal/3.jpg" alt="home"></a>
                                           <h3><a routerLink="/team">Team</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/legalservices"><img src="assets/img/modal/4.jpg" alt="home"></a>
                                           <h3><a routerLink="/legalservices">Legal Services</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/founder"><img src="assets/img/modal/5.jpg" alt="home"></a>
                                           <h3><a routerLink="/founder">Founder</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
