<app-navbar-two></app-navbar-two>

<div class="team-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Our Team</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Our Team</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- <section class="team-section team-style-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.jpg" alt="team member">

                        <div class="team-text">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>Jerry Hudson</h3>
                                    <p>Business Consultant</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.jpg" alt="team member">

                        <div class="team-text">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>Amelia</h3>
                                    <p>Digital Marketer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.jpg" alt="team member">

                        <div class="team-text">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>Henry Hudson</h3>
                                    <p>SEO Expert</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.jpg" alt="team member">

                        <div class="team-text">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>Jainy</h3>
                                    <p>Business Consultant</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/6.jpg" alt="team member">

                        <div class="team-text">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>Charlie</h3>
                                    <p>Problems solver</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/5.jpg" alt="team member">

                        <div class="team-text">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>Linda</h3>
                                    <p>Critical thinker.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->